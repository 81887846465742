*{
  font-family: Roboto;
  overscroll-behavior: none; /* prevent pull-to-refresh effects on mobile */
}

video::-internal-media-controls-overlay-cast-button {
  display: none;
}

/*@media only screen and (max-width:1025px) and (orientation:landscape) {
  body {
    height: 100vw;
    transform: rotate(90deg);
  }
}*/

.PatternBackground{
  background-image: url("restaurant.jpg");
  background-repeat: repeat/no-repeat;
  background-size: 512px 512px;
}

.WhiteBackground{
  background-color: #FFFFFF;
}

@media only screen and (max-width:600px) {
  body {
    background-color: #000000;
  }
}

.App {
  
}

.LoadingTextContainer{
  z-index: -1;
}

.LoadingPastilles{
  transform: translate(-50%, -50%);
  position: absolute;
  top: 40%;
  left: 50%;

  overflow: hidden;
  display: block;
  margin: auto;
}

.LoadingText{
  color: #A5A5A5;
  font-weight: bold;
  text-align: center;

  transform: translate(-50%, -50%);
  position: absolute;
  top: 50%;
  left: 50%;

  overflow: hidden;
  display: block;
  margin: auto;
}

.LoadingSpinner{
  color: #2FC4B5;
}

.StoryVideoContainer {
  position: absolute;
  transform: translate(-50%, -50%);
  top: 50%;
  left: 50%;

  overflow: hidden;/* auto; */
  display: block;
  margin: auto;
}

.StoryVideoContainerPreview {
  position: absolute;
  
  overflow: hidden;
  display: block;
  margin: auto;
}

.StoryVideo {
  z-index: 0;
  position: absolute;
}

.Sparkles{
  position: absolute;
  transform: translate(-50%, -50%);
  top: 50%;
  left: 50%;
  z-index: 20;
  pointer-events: none;
}

.VideoOverlay{
  position: absolute;
}

.FBAvatarContainer{
  border-radius: 50%;
  width: 44px;
  height: 44px;
  pointer-events: none;
}

.RestaurantInfosContainer{
  position: absolute;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  user-select: none;
  -webkit-user-select: none;
}

.RestaurantDescriptionContainer{
  display: flex;
  flex-direction: row;
  align-items: center;

  font-style: italic;
  color: #FFFFFF;
}

.RestaurantName{  
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box; 
  -webkit-line-clamp: 2; /* number of lines to show */
          line-clamp: 2; 
  -webkit-box-orient: vertical;

  font-weight: bold; 
}

.RestaurantBusinessAddress{  
  overflow: hidden;
  text-overflow: ellipsis;
}

.ChatText{
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;

  color: white;
}

.Sticker{
  position: absolute;

  background: #FFFFFF;
  border-radius: 8px;

  display: flex;
  flex-direction: row;
  align-items: center;

  user-select: none;
  -webkit-user-select: none;

  z-index: 10;
}

.Sticker img{
  
}

.StickerText{
  margin-top: -2px;
  font-family: "Roboto condensed";  
}

.inside{
  color: 'black';
}

.menu{
  color: 'black';
}

.menu-logo-container{
  border-radius: 50%;
  border:1px solid; 
  width: 60px;
  height: 60px;
}

.PrimaryButtonText{
  margin-top: -2px;
  font-family: "Roboto condensed";
  font-weight: bold;
}

.secondaryButtonContainerImg{
  position: absolute;
  top: 50%;
  left: 50%;
  margin-right: -50%;
  transform: translate(-50%, -50%);

  width: 24px;
  height: 24px;
}

.TouchToPlay{  
  pointer-events: none;
  transform: translate(-50%, -50%);
  position: absolute;
  top: 50%;
  left: 50%;

  overflow: hidden;
  display: block;

  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;

  background: #00000080;
  padding: 16px;

  font-weight: bold;
  color: #FFFFFF;

  z-index: 100;
}

.Separator{
  border-top: solid #00000040;
}

.booking-title{
  font-style: italic; 
  user-select: none;
  -webkit-user-select: none;
}

.booking-option{
  color: #49454F;
  user-select: none;
  -webkit-user-select: none;
}

.VirtualTourContainer{
  pointer-events: none;
}

.VirtualTour{
  pointer-events: auto;

  transform: translate(-50%, -50%);
  position: absolute;
  top: 50%;
  left: 50%;

  display: block;
  margin: auto;

  z-index: 20;
}

.VirtualTourTitle{
  position: absolute;

  font-weight: bold;
  color: #808080;
  background: #FFFFFFA0;

  z-index: 30;
}

.VirtualTourPano{
  pointer-events: auto;

  z-index: 20;
}


.MediasGalleryContainer{
  pointer-events: none;
}

.MediasGallery{
  pointer-events: auto;

  transform: translate(-50%, -50%);
  position: absolute;
  top: 50%;
  left: 50%;

  display: block;
  margin: auto;

  z-index: 20;
}

.Slide{
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  height: 100%;
}

.MediasGalleryTitle{
  position: absolute;

  font-weight: bold;
  color: #808080;
  background: #FFFFFFC0;

  z-index: 30;
}

.medias-gallery-menu{
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.medias-gallery-menu-modern-title{
  position: absolute; 
  top: 50%; 
  left: 50%;
  transform: translate(-50%, -50%);
}

.medias-gallery-menu-modern-title-sep{
  position:absolute; 
  top: 50%; 
  transform: translate(0%, -50%);
}

.menu-container{
  pointer-events: auto;
  top:0; 
  left: 0;
  display: flex; 
  flex-direction: column; 
  align-content: center; 
  align-items: center;
  background: #FFFFFF; 
  z-index: 30; 
  user-select: none;
  -webkit-user-select: none;
}

.menu-items{

}

.menu-container-scroll-mobile{
  scrollbar-width: none; /* hide scrollbar FF */
  -ms-overflow-style: none;  /* IE and Edge */
}

/* Hide scrollbar for Chrome, Safari and Opera */
.menu-container-scroll-mobile::-webkit-scrollbar {
  display: none;
}

.menu-container-scroll-desktop{
  scrollbar-width: thin;
}

.menu-line-sep-container{
  display: flex; 
  flex-direction: column; 
  align-items: center;
}

.menu-line-container{
  display: flex; 
  flex-direction: row; 
  align-items: center;
}

.menu-line-container-wo-img{
  display: flex;
  flex-direction: column; 
  justify-content:flex-start;
}

.menu-page-title-container{
  display: flex; 
  flex-direction: column;
  align-items: center;
}

.menu-page-title{
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.menu-page-title-separator{
  position:absolute;
  top: 50%; 
  transform: translate(0%, -50%);
}

.menu-entry-title{
  /*white-space: nowrap;*/
  overflow: hidden;
  /*text-overflow: ellipsis;*/
}

.menu-entry-subtitle{
  overflow: hidden;
  /*text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2; 
          line-clamp: 2; 
  -webkit-box-orient: vertical;*/
}

.menu-detail-container{
  overflow-y: auto;
}

.menu-detail-container-scroll-mobile{
  scrollbar-width: none; /* hide scrollbar FF */
  -ms-overflow-style: none;  /* IE and Edge */
}

/* Hide scrollbar for Chrome, Safari and Opera */
.menu-detail-container-scroll-mobile::-webkit-scrollbar {
  display: none;
}

.menu-detail-container-scroll-desktop{
  scrollbar-width: thin;
}

.promotion-container{
  pointer-events: auto;
  top:0; 
  left: 0;
  display: flex; 
  flex-direction: column; 
  align-content: center; 
  align-items: center;
  background: #FFFFFF; 
  z-index: 30; 
  user-select: none;
  -webkit-user-select: none;
}

.circle { 
  width: 74px;
  height: 32px; 
  -moz-border-radius: 50px; 
  -webkit-border-radius: 50px; 
  border-radius: 50px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: center;
}

.nutrients-text{
  font-weight: bold;
  font-size: 10px;
  color: #103c23;
}

.FullScreenContainer{
  position: absolute;
  background: #000000;
  
  width: 100%;
  height: 100%;

  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;

  z-index: 30;
}

.MediasGalleryFullScreen{
  position: absolute;

  z-index: 20;
}

.hours-container{
  -webkit-user-select: none; /* Safari */
  -ms-user-select: none; /* IE 10 and IE 11 */
  user-select: none; /* Standard syntax */
}

.hours-title{
  color: #FFFFFF;
  font-weight: bold;
  position: absolute;
  left: 52%;
  transform: translate(-50%, 0%);

  z-index: 20;
}

.hours-separator{
  position: absolute;
  left: 52%;
  transform: translate(-50%, 0%);

  z-index: 20;
}

.hours-openings{
  position: absolute;

  left: 50%;
  transform: translate(-50%, 0%);

  width: 236px;  
  color: #FFFFFF;

  z-index: 20;
}

/* Hide scrollbar for Chrome, Safari and Opera */
.hours-scrollable-container::-webkit-scrollbar {
  display: none;
}

/* Hide scrollbar for IE, Edge and Firefox */
.hours-scrollable-container {
  overflow-y: scroll;
  -ms-overflow-style: none;  /* IE and Edge */
  scrollbar-width: none;  /* Firefox */
}

.hours-file{
  transform: translate(-50%, -50%);
  position: absolute;
  top: 50%;
  left: 50%;

  display: block;
  margin: auto;

  z-index: 20;
}

.hours-close{
  position: absolute;
  pointer-events: auto;
  cursor: pointer;

  z-index: 20;
}

.reviews-container{
  -webkit-user-select: none; /* Safari */
  -ms-user-select: none; /* IE 10 and IE 11 */
  user-select: none; /* Standard syntax */
}

.review-rating-container{
  position: absolute;
}

.review-rating-vignette{ 
  position: absolute;
  border-radius: 30px;
  color: #FFFFFF;  

  display: flex;
  flex-direction: column;
  align-content: center;
}

.review-rating-name{    
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 1;
          line-clamp: 1; 
  -webkit-box-orient: horizontal;
  
  font-weight: bold;
}

.review-rating-rating{    
  font-style: italic;

  display: flex;
  flex-direction: row;
  align-items: center;   
}

.review-avatar{
  position: absolute;
}

.review-description{
  position: absolute;
  left: 50%; 
  margin-right: -50%;
  transform: translate(-50%, -50%);

  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 6; /* number of lines to show */
          line-clamp: 6; 
  -webkit-box-orient: vertical;
}

.review-background{
  position: 'absolute';
}

.reviews-close{
  pointer-events: auto;
  position: absolute;
  cursor: pointer;

  z-index: 20;
}

.location-chain{
  transform: translate(-50%, -50%);
  position: absolute;
  top: 50%;
  left: 50%;

  display: block;
  margin: auto;

  z-index: 20;
}

.border-img-rev-horiz {
  -webkit-transform: scaleX(-1);
  transform: scaleX(-1);
}

.border-img-rev-vertic {
  -webkit-transform: scaleY(-1);
  transform: scaleY(-1);
}

.border-img-rev {
  -webkit-transform: scale(-1);
  transform: scale(-1);
}

.sticky{
  position: -webkit-sticky; /* Safari */
  position: sticky;
}

.google{
  position: absolute;
  transform: translate(-50%, 0);
  left: 50%;

  display: block;
  margin: auto 0;

  z-index: 20;
}

.BlockingOverlay{
  position: absolute;
  pointer-events: none;
  top: 0px;
  left: 0px;
  background: #FFFFFFB0;

  z-index: 10;
}

.BlockingOverlayClose{
  position: absolute;

  right: 0px;

  pointer-events: none;
  color: #2FC4B5;

  z-index: 10;
}

.FullScreenClose{
  position: absolute;

  color: #2FC4B5;

  z-index: 30;
}

.not-selectable{
  user-select: none;
  -webkit-user-select: none;
}

.maker-sticker-container{
  /*display: grid;
  grid-template-rows: 1px 1px;
  gap: 1px;*/
}

.maker-sticker-container-box {
  cursor: move;
}