.instagram-feed {
    max-width: 540px;   
}
  
.feed-item {
    width: 100%;
    position: relative;
}
  
.feed-item.video {
    cursor: pointer;
}
  
.feed-item.image img {
    width: 100%;
    height: auto;
}
  
.play-pause-overlay {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: rgba(0, 0, 0, 0.5);
    padding: 20px;
    border-radius: 50%;
    cursor: pointer;
}
  
.play-pause-overlay::before { 
    font-family: "Font Awesome 5 Free";
    font-weight: 900;
    font-size: 24px;
    color: white;
    content: "\f04b"; 
}
  
.play-pause-overlay.playing::before {
    content: "\f04c";
}

.instagram-container {
    max-width: 540px;
    margin: 0 auto;
    font-family: "Segoe UI", Roboto, Helvetica, Arial, sans-serif;
    display: flex; /* Use flexbox for layout */
    flex-direction: column; /* Stack content vertically */
    min-height: 100vh; /* Ensure container takes up full viewport height */
}

.instagram-header {
    position: sticky;
    top: 0;
    z-index: 1;
    display: flex;
    justify-content: space-between;
    align-items: center;    
    background-color: white;    
    padding-top: 4px;
    padding-bottom: 6px;
    padding-left: 15px;
    padding-right: 15px;
    border-bottom: 1px solid #eee;
}

.header-title {
    font-weight: bold;
    font-size: 24px;    
}

.header-icons {
    display: flex;
    align-items: center;    
    gap: 15px;
    font-size: 20px;
    height: 100%;
}

.post-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 8px 12px;
    background-color: white;    
}

.post-header-left {
    display: flex;
    align-items: center;
}

.instagram-avatar{
    border: double 2px transparent;
    border-radius: 20px;
    background-image: linear-gradient(white, white), linear-gradient(45deg, #FFC500, 20%, #FFC500 20%, /*30%, #FF1557, 80%,*/#D300C5 75%, #D300C5);
    background-origin: border-box;
    background-clip: padding-box, border-box;
    padding: 2px;
}

.post-icon {
    margin-right: 8px;
    font-size: 16px;
}

.post-title {
    font-weight: 600;
    font-size: 14px;
}

.post-footer {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 8px 12px;
    background-color: white;
}

.post-footer-left {
    display: flex;
    align-items: center;
    gap: 12px;
}

.post-footer-icon {
    font-size: 20px;
}

.post-likes-comments {
    padding: 8px 12px;
    background-color: white;
    font-size: 14px;
}

.likes-count {
    font-weight: 600;
}

.comment-username {
    font-weight: 600;
}

.view-more-comments {
    color: #999;
}

.timestamp {
    color: #999;
    font-size: 12px;
}

.sponsored-tag {
    font-size: 12px;   
    font-weight: 400; 
}

.cta-button {
    border: none;
    color: white;
    cursor: pointer;
    z-index: 10;
}

.cta-container {
    padding: 16px 12px 16px 8px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    z-index: 10;
    cursor: pointer;
}

.cta-text {
    font-weight: 600;
    font-size: 14px;
}

.cta-icon {
    font-size: 16px;
}


.heart-icon-container {
    position: relative;
    margin-top: 6px;
}

.notification-badge {
    position: absolute;
    top: -2px;
    right: -4px;
    width: 8px;
    height: 8px;
    background-color: red;
    border: 2px solid #fff;
    border-radius: 50%;
}


.instagram-bottom-nav {
    background-color: white;
    position: fixed;  /* Keep it at the bottom */
    bottom: 0;
    
    width: 100%;
    max-width: 540px;
    margin: 0 auto;
    display: flex;
    justify-content: space-around; /* Distribute icons evenly */
    align-items: center;  /* Vertically center icons */
    padding: 10px 0; /* Add some padding */
    border-top: 1px solid lightgray; /* Optional: add a top border */
    z-index: 1000; /* Ensure it's on top of other elements */
}

.nav-icon {    
    display: flex;     /* Needed to center the SVGs */
    align-items: center;
    justify-content: center;
    width: 20%; /* Make sure icons take up equal width - adjust as needed */
    font-size: 24px; /* Adjust icon size */
}


/* To avoid overlapping with the bottom navbar: */
.instagram-feed {
  padding-bottom: 60px; /* Adjust this value to match the navbar height */
}


