.FirstSectionContainer{
  
}

.yellowParallelogram {
padding: 6px;
display: inline-block;
background: #FCFD0A;
transform: skewX(-20deg);
}

.textOverYellowParallelogram {
    transform: initial;
    transform: skewX(20deg);
}