*{
  font-family: Roboto;
}

.maker-logo-container{
    border-radius: 50%;
    border:1px solid #C0C0C0; 
    width: 60px;
    height: 60px;
}

.maker-sticker-container{
  height: 100px;
  width: 56px;
}

.maker-material-button{
  
}

.maker-primary-button{
    background: #FFFFFF;
    border-radius: 20px;
    padding: 8px;
  
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    z-index: 10;  
    user-select: none;
    -webkit-user-select: none;
  }

  .maker-primary-button-text{
    margin-left: 4px;
    margin-top: -2px;
    font-family: "Roboto condensed";
    font-size: 18px;
    font-weight: bold;
  }

  /*https://web.dev/drag-and-drop/*/
  .maker-gallery-container{
    display: grid;
    grid-template-columns: repeat(5, 1fr);
    gap: 8px;
  }

  .maker-gallery-container-box {
    cursor: move;
  }

  .maker-gallery-container-box img{
    object-fit: cover;
    height: 100px;
    width: 56px;
  }

  .maker-gallery-container-box video{
    object-fit: cover;
    height: 100px;
    width: 56px;
  }

  .maker-menu-box{
    height: 100px;
    width: 56px;
    border:1px solid #C0C0C0;
    border-radius: 4px;
  }

  .maker-menu-box img{
    position: absolute;    
    top: 32px;
    left: 10px;
    height: 36px;
    width: 36px;
  }

  .maker-day-menu-box-img{
    object-fit: cover;
    height: 100px;
    width: 56px;
  }

  .maker-gallery-container-add{
    user-select: none;
    -webkit-user-select: none;
    height: 100px;
    width: 56px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    border:1px solid #C0C0C0;
    color: #C0C0C0;
    font-size: xx-large;
    border-radius: 4px;
  }

  .stat-restaurant-names{
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box; 
    -webkit-line-clamp: 2; /* number of lines to show */
            line-clamp: 2; 
    -webkit-box-orient: vertical;
  }